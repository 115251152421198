#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background: #ffffff;
}

.ant-layout {
  background: #ffffff;
}

.anticon-delete svg {
  color: red !important;
}
