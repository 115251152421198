.username {
  padding: 6px 15px;
  background-color: #264491 !important;
  color: white !important;
}

.username::selection {
  background: none !important;
  color: white !important;
}

.username > span[role="img"] {
  width: 14px;
}

.custom-menu-sider > li {
  display: flex;
  flex-direction: column;
  height: auto !important;
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.custom-menu-sider > li > span[role="img"] {
  width: 24px !important;
  padding: 0;
}

.custom-menu-sider > li > span {
  margin: 0 !important;
}

.custom-menu-sider__collapsed > li {
  align-items: center;
  position: relative;
}

.custom-menu-sider__collapsed > li * > a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ant-menu-title-content {
  display: inline-flex;
  min-width: 80%;
  color: black;
}

.ant-menu-item-selected {
  > * {
    color: white !important;
    & > * {
      color: white !important;
    }
  }
}

.ant-dropdown-menu-item-selected,
.ant-select-tree-node-selected > span {
  color: white !important;
}

.ant-select-item-option-selected {
  color: white !important;
}

.ant-table-row-selected {
  > td {
    color: white !important;
  }
  > td > * {
    color: white !important;
  }
}
